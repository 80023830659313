$(document).ready(function() {
  function showDisability() {
    if ($('#program_review_disability_id').val() == '2') {
      //TODO: hay que manetener siempre el mismo ID
      $('#percent_disability_review').show()
    } else {
      $('#percent_disability_review').hide()
    }
  }

  function showLocationExtraFields() {
    switch ($('#program_review_geographic_coverage').val()) {
      case 'Local':
        $('.formGeoCityInput').show()
        $('.formGeoCityInput')
          .find('input')
          .prop('required', true)
        $('.formGeoCountyInput').show()
        $('.formGeoStateInput').show()
        $('.formGeoStateInput')
          .find('select')
          .prop('required', true)
        break
      case 'Regional':
        $('.formGeoCityInput').hide()
        $('.formGeoCityInput')
          .find('input')
          .prop('required', false)
        $('.formGeoCountyInput').hide()
        $('.formGeoStateInput').show()
        $('.formGeoStateInput')
          .find('select')
          .prop('required', true)
        break
      case 'National':
        $('.formGeoCityInput').hide()
        $('.formGeoCityInput')
          .find('input')
          .prop('required', false)
        $('.formGeoCountyInput').hide()
        $('.formGeoStateInput').hide()
        $('.formGeoStateInput')
          .find('select')
          .prop('required', false)
        break
    }
  }

  function initAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('program_review_location'),
      { types: ['address'], componentRestrictions: { country: 'us' } }
    )

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(['address_component'])

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', fillInLocation)
  }

  function fillInLocation() {
    var place_location = autocomplete.getPlace()

    for (var i = 0; i < place_location.address_components.length; i++) {
      var addrComp = place_location.address_components[i]
      if (addrComp.types[0] == 'administrative_area_level_1') {
        var state = addrComp.short_name
      }

      if (addrComp.types[0] == 'locality') {
        var city = addrComp.long_name
      }
    }

    document.getElementById('program_review_city_input').value = city
    document.getElementById('program_review_state_input').value = state
  }

  function initAutocompleteCityReview() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    autocomplete_city_review = new google.maps.places.Autocomplete(
      document.getElementById('program_review_city'),
      { types: ['(cities)'], componentRestrictions: { country: 'us' } }
    )

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.

    autocomplete_city_review.setFields(['address_component'])

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete_city_review.addListener('place_changed', fillInCityReview)
  }

  function fillInCityReview() {
    // Get the place details from the autocomplete object.
    var place_city_review = autocomplete_city_review.getPlace()

    for (var i = 0; i < place_city_review.address_components.length; i++) {
      var addrComp = place_city_review.address_components[i]
      if (addrComp.types[0] == 'administrative_area_level_1') {
        var state = addrComp.short_name
      }

      if (addrComp.types[0] == 'locality') {
        var city = addrComp.long_name
        document.getElementById('program_review_city').value = city
      }
    }
    // first, clear old state
    $('select#program_review_state_ids option')
      .prop('selected', false)
      .trigger('change')

    // an update it with teh recently extracted from data
    $("select#program_review_state_ids option[short_name='" + state + "']")
      .prop('selected', true)
      .trigger('change')
  }

  const reviewForm = document.getElementById('form_program_review')

  if (reviewForm) {
    showDisability()
    $('#program_review_disability_id').on('change', showDisability)

    showLocationExtraFields()
    $('#program_review_geographic_coverage').on(
      'change',
      showLocationExtraFields
    )

    initAutocomplete()
    initAutocompleteCityReview()
  }

  //redirect link
  if ($('#redirect_link').length > 0) {
    var delay = 10
    const url = $('#redirect_link').attr('href')
    function countdown() {
      setTimeout(countdown, 1000)
      $('#countmesg').html('Redirecting in ' + delay + ' seconds.')
      delay--
      if (delay < 0) {
        window.location = url
        delay = 0
      }
    }
    countdown(delay, url)
  }

  function btnView(btn) {
    btn.removeClass('btn-secondary').addClass('btn-primary')
    btn.removeClass('btn-review-close').addClass('btn-review-view')
    btn.html('View')
  }

  function btnClose(btn) {
    $('html, body').animate({ scrollTop: 0 }, 'slow')
    btn.removeClass('btn-primary').addClass('btn-secondary')
    btn.removeClass('btn-review-view').addClass('btn-review-close')
    btn.html('Close')
  }

  $('#collapseGeneral').on('hidden.bs.collapse', function() {
    const btn = $('#btnGeneral')
    btnView(btn)
  })
  $('#collapseGeneral').on('shown.bs.collapse', function() {
    const btn = $('#btnGeneral')
    btnClose(btn)
  })

  $('#collapseContact').on('hidden.bs.collapse', function() {
    const btn = $('#btnContact')
    btnView(btn)
  })
  $('#collapseContact').on('shown.bs.collapse', function() {
    const btn = $('#btnContact')
    btnClose(btn)
  })

  $('#collapseGeographic').on('hidden.bs.collapse', function() {
    const btn = $('#btnGeo')
    btnView(btn)
  })
  $('#collapseGeographic').on('shown.bs.collapse', function() {
    const btn = $('#btnGeo')
    btnClose(btn)
  })
  $('#collapseEligibility').on('hidden.bs.collapse', function() {
    const btn = $('#btnEligibility')
    btnView(btn)
  })
  $('#collapseEligibility').on('shown.bs.collapse', function() {
    const btn = $('#btnEligibility')
    btnClose(btn)
  })
})
