import { ModalMessage } from './message'
import { Helper } from './helper'
$(document).ready(function() {
  function reset_search_form() {
    // $("#form_desktop_search").find("input[type=text], select").val("");

    // at moment Gender and Education Level don't have "nil/blank/empty" value
    // lets select Female and No requirements as default
    $('#form_object_city').val('')
    $('#form_object_zip').val('')
    $('#form_desktop_search')
      .find('option')
      .prop('selected', false)
    $('.form-group#percent-disability').hide()
    $('.selectpicker').selectpicker('refresh')
  }

  $('#switchInfo').change(function() {
    const checked = $(this).prop('checked')
    if (checked) {
      window.location.reload()
    } else {
      reset_search_form()
    }
  })

  // Case Search

  $('#case_identificator').on('change', function() {
    const case_identificator = $(this).val()

    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: { case_identificator: case_identificator },
      url: '/case_existence',
      cache: false
    })
      .done(function(data) {
        const msg = new ModalMessage()
        if (data.case_existence) {
          msg.showMsg(
            'Invalidad name for Case ID.',
            'Please enter another name. It already exists.'
          )
        }
      })
      .fail(function() {
        console.log('error(?)')
      })
  })

  if ($('#case_search_case_id').data('newcase')) {
    $('#case_search_case_id option:contains("New Case")').attr(
      'selected',
      'selected'
    )
    $('select#case_search_case_id').selectpicker('refresh')
    $('#case_identificator').removeClass('d-none')
  } else {
    const case_id = Helper.urlParam('case_id', window.location.href)
    $('#case_search_case_id').val(case_id)

    $('#case_search_case_id').selectpicker('refresh')
  }

  $(document).on('change', '#case_search_case_id', function() {
    var user_id = $(this).data('user-id')
    var case_id = $(this).val()

    if ($('select#case_search_case_id option:selected').text() == 'New Case') {
      window.location.href = '/search?new_case=true'
    } else {
      const case_id = $('select#case_search_case_id option:selected').val()
      window.location.href = `/search?case_id=${case_id}`
    }
  })
  // End Case Search

  $(document).on('click', '.rating_program', function() {
    $('.list-stars').addClass('d-flex')
  })

  $('.back').on('click', function() {
    window.history.back()
  })

  $('#search-result-show-All').on('click', function() {
    $('.search-results-National-program').show()
    $('.search-results-Regional-program').show()
    $('.search-results-Local-program').show()
    return false
  })

  $('#search-result-show-National').on('click', function() {
    $('.search-results-National-program').show()
    $('.search-results-Regional-program').hide()
    $('.search-results-Local-program').hide()
    return false
  })

  $('#search-result-show-Regional').on('click', function() {
    $('.search-results-National-program').hide()
    $('.search-results-Regional-program').show()
    $('.search-results-Local-program').hide()
    return false
  })

  $('#search-result-show-Local').on('click', function() {
    $('.search-results-National-program').hide()
    $('.search-results-Regional-program').hide()
    $('.search-results-Local-program').show()
    return false
  })

  $('.edit_case').on('click', function() {
    const url = $(this).data('url')
    const caseId = $('#case_search_case_id').val()
    if (caseId) {
      window.location.href = `${url}?case_id=${caseId}`
    }
  })

  //Search button: first validate required fields
  $(document).on('click', '#btn-desktop-search-programs', function() {
    const msg = new ModalMessage()
    //if new case add a name
    if ($('select#case_search_case_id option:selected').text() == 'New Case') {
      if (!$('#case_identificator').val()) {
        msg.showMsg('Search with Case ID', 'Please add a name for the new case')
        return false
      }
    }

    var data = $('form').serialize()

    if ($('select#case_search_case_id option:selected').text() == 'New Case') {
      const case_identificator = $('#case_identificator').val()
      data = data + `&form_object[case_identificator]=${case_identificator}`
    } else {
      if ($('select#case_search_case_id option:selected').length) {
        const case_id = $('select#case_search_case_id option:selected').val()
        data = data + `&form_object[case_id]=${case_id}`
      }
    }

    const url = '/search'
    const resultUrl = '/search/results'

    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: data,
      url: url,
      cache: false
    })
      .done(function(data) {
        if (data.search_id) {
          window.location.href = `${resultUrl}?search_id=${data.search_id}`
        } else {
          let fields = ''
          if (data.case_identificator) {
            fields = 'Case ID is already taken <br>'
          }
          Object.keys(data.errors).forEach(key => {
            let value = data.errors[key]
            fields += `${value} <br>`
          })
          msg.showMsg('Required Fields', fields)
        }
        return false
      })
      .fail(function() {
        return false
      })

    // to prevent duplicated form submit
    return false
  })

  $('#icon-edit-my-info').on('click', function(e) {
    //if search with a case ID and not case selected don't let edit
    const msg = new ModalMessage()
    console.log('case ID', $('#case_search_case_id').val())
    if (
      $('#switchCaseInfo').is(':checked') &&
      $('#case_search_case_id').val() === null
    ) {
      msg.showMsg('Edit Data', 'Please, choose a case ID to Edit')
      e.preventDefault()
      return false
    }
  })

  $('.change-step').on('click', function() {
    const tab = $(this).data('step')
    $(`#seach-tab a[href="#${tab}"]`).tab('show')
  })

  $('.change-mobile-step').on('click', function() {
    const tab = $(this).data('step')
    $('.' + tab).collapse('show')
    $('html, body').animate({ scrollTop: 0 }, 'slow')
  })

  $('#clear-search').on('click', function() {
    reset_search_form()
  })

  $('#search_keywords').on('input', function() {
    console.log('fire!')
    const keywords = $('#search_keywords').val()

    const suggestionstUrl = '/search_suggestions'

    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: { q: keywords },
      url: suggestionstUrl,
      cache: false
    })
      .done(function(data) {
        console.log(data)
        return false
      })
      .fail(function() {
        return false
      })
  })

  $('#search-keywords-button').on('click', function() {
    const keywords = $('#search_keywords').val()

    const resultUrl = '/search/results'

    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: { keywords: keywords },
      url: '/global_search',
      cache: false
    })
      .done(function(data) {
        console.log(data)
        if (data.search_id) {
          window.location.href = `${resultUrl}?search_id=${data.search_id}`
        } else {
          console.log('???? PATH 1')
        }
        return false
      })
      .fail(function() {
        return false
      })
  })

  if ($('#suicide-alert').val() == 'true') {
    showSuicideAlert($('#suicide-alert').data('img'))
  }
  $('.suicide-alert-msg').on('click', function() {
    showSuicideAlert($('.suicide-alert-msg').data('img'))
  })

  function showSuicideAlert(imageUrl) {
    const msg = new ModalMessage()

    msg.showMsg(
      'Veterans Crisis Line',
      `<div class="suicide">
    <div class="header">
      Please contact us today. No matter what you are experiencing, there is support, and you are not alone.
    </div>
    <div class="body">
      <a href="https://www.veteranscrisisline.net/" target="_blank" class="logo-crisis">
          <img src="${imageUrl}" class="img-fluid" alt="Veterans Crisis Line Logo">
      </a>  
      <div class="font-weight-bold title-contact">Contact Information</div>
      <div>Call <a href="tel:+988">988</a> and Press 1</div>
      <div>Text 838255</div>
      <div><a href="https://www.veteranscrisisline.net/get-help/chat" target="_blank">Chat Online</a></div>
      <div><a href="tel:+18007994889">Support for deaf and hard of hearing</a></div>
    </div>
  </div>`
    )
  }

  $('#proximity').slider()
  $('#proximity').on('slideStop', function(slideEvt) {
    $('#number-miles').html(slideEvt.value)
    const url = $(this).data('url')
    const query = window.location.search.substring(1).split('&')
    const params = query.filter(function(item) {
      return !item.includes('distance')
    })

    window.location = `${url}?distance=${slideEvt.value}&${params.join('&')}`
  })

  $('.apply-filters').on('click', function() {
    window.location.href = '/search/new_results'
  })

  $('.clear-filters').on('click', function() {
    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: {},
      url: '/search/clear_all',
      cache: false
    })
    return false
  })

  $('.desktop-clear-filters').on('click', function() {
    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: {},
      url: '/search/clear_all_desktop',
      cache: false
    })
    return false
  })

  $('.btn-filter-suboptions').on('click', function() {
    window.location = $(this).data('url')
  })
  //$('#filter_yes_disability').on('click', function() {
  //  window.location = $(this).data('url')
  //})

  $('.disability-filter-option').on('click', function() {
    $('#form-disability-filter').submit()
  })

  $('.service-filter-option').on('click', function() {
    const category = $(this).data('category')
    const category_id = $(this).data('category-id')
    var input = $('<input>')
      .attr('name', 'service_needs_categories')
      .val(category)
    $('#form-service-filter').append($(input))
    var input = $('<input>')
      .attr('name', 'service_needs_category_id')
      .val(category_id)
    $('#form-service-filter').append($(input))
    $('#form-service-filter').submit()
  })

  $(document).on('click', '.desktop-filter-option-input', function() {
    const filter_name = $(this).data('filter-name')
    const filter_value = $(this).val()

    var params = {}
    params[filter_name] = filter_value

    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: params,
      url: '/search/update_filters_desktop',
      cache: false
    })
      .done(function(data) {
        console.log('ok')
        // $('#collapse'+filter_name).collapse();
      })
      .fail(function() {
        console.log('fail')
      })
  })

  $(document).on('click', 'input#option-yes', function() {
    const filter_name = 'disability'
    const filter_value = 'Yes'

    var params = {}
    params[filter_name] = filter_value

    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: params,
      url: '/search/update_filters_desktop',
      cache: false
    })
      .done(function(data) {
        console.log('testing')
        $('#percent-filters').removeClass('d-none')
        // $('#collapse'+filter_name).collapse();
      })
      .fail(function() {
        console.log('fail')
      })
  })

  $(document).on('click', '.percent-disability-input', function() {
    const filter_name = 'percent_disability'
    const filter_value = $(this).val()

    var params = {}
    params[filter_name] = filter_value

    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: params,
      url: '/search/update_filters_desktop',
      cache: false
    })
      .done(function(data) {
        console.log('ok')
      })
      .fail(function() {
        console.log('fail')
      })
  })

  $(document).on('click', '.service-need-filter-input', function() {
    const filter_name = 'service_needs_subcategories'
    const filter_value = $(this).val()

    var params = {}
    params[filter_name] = filter_value

    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: params,
      url: '/search/update_filters_desktop',
      cache: false
    })
      .done(function(data) {
        console.log('ok')
      })
      .fail(function() {
        console.log('fail')
      })
  })

  $(document).on('click', '.clear-single-filter', function() {
    const filter_name = $(this).data('filter-name')

    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: { filter_name: filter_name },
      url: '/search/clear_single_filter',
      cache: false
    })
      .done(function(data) {
        console.log('ok')
      })
      .fail(function() {
        console.log('fail')
      })
  })

  $(document).on('click', '.filter-option-input', function() {
    const filter_name = $(this).data('filter-name')
    const filter_value = $(this).val()

    var params = {}
    params[filter_name] = filter_value

    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: params,
      url: '/search/update_filters',
      cache: false
    })
      .done(function(data) {
        console.log('ok')
        $('#collapse' + filter_name).collapse()
      })
      .fail(function() {
        console.log('fail')
      })
  })

  if($('div.main-content-mobile.search').data('from-mobile-chat')){
    window.addBotMessageToChat("Results loaded, close chat window to view.")
  }
})
