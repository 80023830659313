/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import './stylesheets/application'
import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-select'
import 'bootstrap-slider'
import 'jquery-validation'
import 'axios'
import 'lodash'
import 'tinymce'
import Rails from 'rails-ujs'
import './case'
import './chatbot'
import './disclaimer'
import './filter'
import './history'
import './home'
import './events-manager'
/*import './map'  it's not working */
import './myactivity'
import './myInfo'
import './program'
import './recommend'
import './report'
import './review'
import './search'
import './share'
import './user'
import './appcues'
import './pagy.js.erb'
import './navbar'
import './flickity.pkgd.min.js'
import './readmore.min.js'
import './typeahead.jquery.min.js'
Rails.start()
