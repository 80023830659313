export class  Helper {

    static urlParam = (param,url) =>{
        var results = new RegExp('[\?&]' + param + '=([^&#]*)').exec(url);
        if (results==null){
           return null;
        }
        else{
           return results[1] || 0;
        }
    }

}