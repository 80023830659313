$(function() {
  if (window.Appcues != undefined && window.Appcues != null) {
    Appcues.page();
    console.log('Appcues.page() called');
    const user_agent = ( navigator.userAgent.indexOf("Mobile") || navigator.userAgent.indexOf("webOS") );
    // value -1 means Desktop
    // value different of -1 means Mobile
    const is_mobile = (user_agent !== -1)
    const user_id = $('#user_data').data('user-id');
    const user_email = $('#user_data').data('user-email');
    window.Appcues.identify(user_id, {is_mobile: is_mobile, user_email: user_email});
  }
});
