$( document ).ready(function() {

  $.validator.addMethod("pwcheck", function(value) {
    return /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) // consists of only these
        && /[A-Z]/.test(value) // has a lowercase letter
        && /\d/.test(value) // has a digit
 });

  $("form.form-signup").validate({
    rules: {
      'user[email]': {
        required: true,
        email: true
      },
      'user[password]':{
        required: true,
        minlength: 8,
        pwcheck: true
      },
      'user[password_confirmation]':{
        required: true,
        equalTo: "#formPasswordInput"
      }
    },
    messages: {
      'user[password]': {
        pwcheck: "The password is not valid. Check password policy.",
      },
    }
  });
  $("form.form-signup2").validate({
    rules: {
      'user[name]': "required",
      'user[last_name]': "required",
      'user[signup_organization_name]': 'required'
    }
  });
  $("form.form-login").validate({
    rules: {
      'user[email]': {
        required: true,
        email: true
      },
      'user[password]':{
        required: true,
      }
    }
  });
  $("form.forgot-password").validate({
    rules: {
      'user[email]': {
        required: true,
        email: true
      }
    }
  });

  $("form.reset-password").validate({
    rules:{
      'user[password]':{
        required: true,
        minlength: 8,
        pwcheck: true
      },
      'user[password_confirmation]':{
        required: true,
        equalTo: "#formPasswordInput"
      }
    },
    messages: {
      'user[password]': {
        pwcheck: "The password is not valid. Check password policy.",
      },
    }
  });

  
  if ($('#formOrganizationInput').val() 
      && $('#formOrganizationInput').val() !== "None" 
      && $('#formOrganizationInput').val().length > 2 ) {
    $('.js--link-code-field').removeClass('hidden');
  }

  $('#formOrganizationInput').on('input',function(e){
    if (e.target.value.length > 2 && e.target.value !== "None") {
      $('.js--link-code-field').removeClass('hidden');
    } else {
      $('.js--link-code-field').addClass('hidden');
    }
  });

});