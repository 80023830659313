$(document).ready(function() {
  $('.js--search-navbar').on('click', function(e) {
    $('.js--search-form-navbar').toggle(150);
    e.preventDefault();
    return false;
  });

  $('#search-navbar').on('submit', function() {
    const keywords = $('#search_keywords').val()
    const resultUrl = '/search/results'

    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: {keywords: keywords},
      url: '/global_search',
      cache: false,
    })
    .done(function(data){
      console.log(data)
      if (data.search_id){
        window.location.href=`${resultUrl}?search_id=${data.search_id}`;
      }else{
        console.log("???? PATH 1")
      }
      return false;
    })
    .fail(function(){
      return false;
    });

    return false;
  })

  $('.global-search-input').on('input', function() {
    if ($('.global-search-input').val().length >= 3) {
      $('.global-search-submit').show(150);
    } else {
      $('.global-search-submit').hide(150);
    }
  })
});
