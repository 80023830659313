$( document ).ready(function() {
  $("form.recommend-form").validate({
    rules: {
      'program[program_name]': {
        required: true
      },
      'program[organization_name_recommended]':{
        required: true
      },
      'program[website]':{
        required: true,
        url: true
      }
    }
  });

});