jQuery(function() {
   /**
    * Init chat
    */

  function sendSystemStartChat(){
    lex_call($('div.chatbox-holder').data('url'), 'hello', $('#authenticity_token').val(), true); 
  }

  // Dynamic placeholder
  const input_placeholders = ["e.g. hello", "e.g. I am looking for medical supplies", "e.g. Find a resource", "e.g. I want to schedule a demo", "e.g. Product tour", "Type your question here..."];
  let active_placeholders = input_placeholders;
  let placeholder_counter = 0;
  let loopLength = input_placeholders.length;
  // setIntervalId is the timer for the placeholders
  let setIntervalId;
  // setTimeoutId is the timer for launching the systemStartChat message
  let setTimeoutId;

  let setTimeoutCloseChatId;

  function scrollToChatBottom(speed){
    $(".chat-messages").stop().animate({ scrollTop: $(".chat-messages")[0].scrollHeight}, speed);
  }

  function dynamic_placeholder(active_placeholders){
    setIntervalId = setInterval(function(){
     if(placeholder_counter < loopLength){
        let newPlaceholder = active_placeholders[placeholder_counter];
        placeholder_counter++;
        $('input.chat-input').attr('placeholder',newPlaceholder);
     } else {
        $('input.chat-input').attr('placeholder',active_placeholders[0]);
        placeholder_counter = 0;
     }
    },4000);
  }

  dynamic_placeholder(active_placeholders);

  // end dynamic placeholder

  const chatbot_open = localStorage.getItem("chat_open");
  console.log('chatbot_open' + chatbot_open)



  if (chatbot_open == 'true'){
    openChat(false)
    const chatHistory = localStorage.getItem("chat_history");
    $('.chat-messages').html(chatHistory);
    // scroll fast when we are reloading/or changing the page
    scrollToChatBottom(10);
  } else {
    //15 seconds open chat
    console.log("inside !chatbot_open")
    documentTitle = document.title
    // if the user is at the homepage (only a the homepage)
    // we show automatically the chatbot after 3 minutes
    // unless the user has already opened it by itself
    // (if the user does that, we use clearTimeout to kill this timer)
    if (documentTitle == 'Home | PATRIOTlink'){
      initChatTimer()
    }

  }

  function initChatTimer(){
    setTimeoutId = setTimeout(function(){
      console.log("triggered timeout openChat")
      openChat(true);
      // 120 seconds
    }, 120000);
  }

  // on page load, send the systemStartChat message to lex
  // if ($('.chat-messages').length) {
  //  const at  = $('#authenticity_token').val();
  //  const url = $('div.chatbox-holder').data('url');
  //  const user_text = 'systemStartChat';
  //  lex_call(url, user_text, at);
  // }
  


  function trigger_anything_else(){
    const at  = $('#authenticity_token').val();
    const url = $('div.chatbox-holder').data('url');
    const user_text = 'trigger anything else intent';
    lex_call(url, user_text, at);
  }

  function trigger_user_type(){
    const at  = $('#authenticity_token').val();
    const url = $('div.chatbox-holder').data('url');
    const user_text = 'what type of user are you';
    lex_call(url, user_text, at);
  }

  function launch_survey(){
    const at  = $('#authenticity_token').val();
    const url = $('div.chatbox-holder').data('url');
    const user_text = 'survey';
    lex_call(url, user_text, at);
  }


  function fill_chat(div){

    // the older visible conversation-id
    let olderConversationId = $('.message-box-holder[data-conversation-id]').data("conversation-id")
    console.log("older")
    console.log(olderConversationId)

    if (div.scrollTop < 10) {
      $(".loading-messages").show();
      $.ajax({
        type: "GET",
        dataType: 'JSON',
        url: '/conversations',
        data: {
          session_id: "123test",
          older_conversation_id: olderConversationId
        },
      })
      .done( function(r){
        $('.chat-messages').prepend(r.html);
      })
      .always( function(){
        $(".loading-messages").hide();
      });
      
    }
  }

  // load previous messages disabled
  // we are removing messages when the user closes the chat
  // $("div.chat-messages").on('scroll', _.debounce(function() {
  //   fill_chat(this);
  // }, 1000));

  $("#message-send").on('click', function(){
    const user_text = $('input.chat-input').val();
    const url = $('div.chatbox-holder').data('url');
    const at  = $('#authenticity_token').val();
    if (user_text == ''){
      return false;
    } 
    // update chat history
    $('.chat-messages').append('<div class="message-box-holder"><div class="message-box">' + user_text + '</div></div>');
    scrollToChatBottom(1000);
    $('input.chat-input').val('');

    lex_call(url, user_text, at)
  });


  $(".chat-input").on('keypress', function(event){
    if(event.which == 13) {
      $("#message-send").trigger('click');
    }
  });

  function openChat(initChat){
    console.log("openChat called")
    localStorage.setItem("chat_open", true);
    $("#top-close-text").hide();
    $('.chatbox').removeClass('chatbox-min');
    // if the user open the chat by itself, we have to stop
    // the setTimeout timer for automaically open and show the systemStartChat message
    clearTimeout(setTimeoutId);

    if(initChat){
      sendSystemStartChat();
    }
    
  }

  function deleteChatHistory(){
    $('div.chat-messages').html("")
  }

  function closechat(){
    console.log("closechat called")
    localStorage.setItem("chat_open", false);
    $('.chatbox').addClass('chatbox-min');
    $("#top-close-text").show();
    deleteChatHistory();
  }

  function addBotMessageToChat(message){
    $('.chat-messages').append('<div class="message-box-holder"><div class="message-box message-bot">' + message + '</div></div>');
  }

  window.addBotMessageToChat = function addBotMessageToChat(message){
    $('.chat-messages').append('<div class="message-box-holder"><div class="message-box message-bot">' + message + '</div></div>');
  }

  function closeChatWithMessage(){
    console.log("closechat with message called")
    addBotMessageToChat("Good bye, please revisit the chat anytime you need assistance.")
    setTimeoutCloseChatId = setTimeout(function(){
      console.log("triggered timeout CloseChat")
      closechat();

    }, 5000);
  }

  function closeChatTimer(){
    setTimeoutCloseChatId = setTimeout(function(){
      console.log("triggered timeout CloseChat")
      closechat();
    }, 5000);
  }


  function lex_call(url, user_text, at, init_chat){
    addTyping();
    scrollToChatBottom(1000);
    console.log("lex_call: " + user_text)
    setTimeout(function(){
      $.ajax({
      type: "POST",
      dataType: 'JSON',
      url: url,
      data: {
        "session_id": "123test",
        "text": user_text,
        "authenticity_token": at
      }
      })
      .done( function(r){
        if (r.status !== 204) {

          // removes empty response before triggering UserType
          if (r.action == 'UserType'){
            r.html = ""
          }

          $('.chat-messages').append(r.html);

          if (r.input_placeholders.length > 0) {
            active_placeholders = r.input_placeholders
          } else {
            active_placeholders = input_placeholders
          }

          clearInterval(setIntervalId);
          // if the user types something, we prevent closing the chat
          clearTimeout(setTimeoutCloseChatId);

          dynamic_placeholder(active_placeholders);

          console.log("active contexts")
          console.log(r.active_contexts)
          console.log("intent name")
          console.log(r.intent_name)
          console.log("dialog state")
          console.log(r.dialog_state)
          //console.log("intent_score")
          //console.log(r.intent_score)
          //console.log("alternative intents")
          //console.log(r.alternative_intents);
          scrollToChatBottom(1000);
          //if (user_text == 'systemStartChat'){ // to auto initiaized chat
          //  openChat();
          //}

          if (r.action == 'CloseChat') {
            closechat();
          }
          if (r.action == 'CloseChatWithMessage') {
            closeChatWithMessage();
          }
          if (r.action == 'AskAnythingElse') {
            trigger_anything_else();
          }
          if (r.action == 'LaunchSurvey') {
            launch_survey();
          }
          if (r.action == 'UserType') {
            trigger_user_type();
          }
          if (r.action == 'CloseChatTimer') {
            closeChatTimer();
          }
        }
      })
      .fail(function(r){
        addBotMessageToChat(r.responseJSON.error)
        scrollToChatBottom(1000)
      })
      .always( function(){
         removeTypig();
         // store current chat history in localStorage
         localStorage.setItem("chat_history", $('.chat-messages').html());
      });
    }, 500);
  }

  function lex_active_session_call(){
    console.log("lex_active:session_call...")
    let active;
    $.ajax({
      type: "GET",
      dataType: 'JSON',
      url: '/lex/answers/active',
      data: {
        "session_id": "123test"
      }
    })
    .done( function(r){
      console.log("r (en done lex_active_session)")
      console.log(r)
      if (r.active !== "true") {
        sendSystemStartChat();
      }
    })
    .fail(function(r){
      console.log("LEX ACTIVE SESSION FAIL")
      console.log(r)
    });
  }

  function addTyping(){
     $('.chat-messages').append(`<div class="message-box-holder typingHolder">
          <div class="message-box message-bot">
              <div class="typing"></div>
          </div>
      </div>`);
  }

  function removeTypig(){
    $(".typingHolder").hide();
  }

  /*open chat*/ 
  $('#top-close-text').on('click',function(){
      openChat(true);
  });
  /* close chat */
  $('#close-chat').on('click',function(){
     closechat();
  });


  $("body").on('click', ".response-card-option", function(){
        const value = $(this).data('value');
        const url = $('div.chatbox-holder').data('url');
        const at  = $('#authenticity_token').val();

        // first, let's remove card-clicked class for all options in this cards
        $(this).closest('ul.list-group').find("li.card-clicked").removeClass("card-clicked");
        $(this).closest('li.list-group-item').addClass("card-clicked");
        // $(this).closest('ul.list-group').children()

        // update chat history
        // $('.chat-messages').append('<div class="message-box-holder"><div class="message-box">' + user_text + '</div></div>');

        $('textarea.chat-input').val('');

        lex_call(url, value, at)
      });

  const product_tour = $('div#main-wrapper').data('product-tour');
  if(product_tour){
    closechat();
    Appcues.show('3bb03c4b-a311-4a70-a6bb-aa9e59507a5f')
  }

  const search_from_chat = $('div#main-wrapper').data('from-chat');
  if(search_from_chat){
    addBotMessageToChat("To narrow your search try the following tips:</br>a. Ensure a location is seleted</br>b. Use the Proximity slider</br>c. Use the Filter to narrow results</br>d. Use specific phrases in the keyword search such as “Food pantry” or “Foodbank”, instead of “Food”");
    localStorage.setItem("chat_history", $('.chat-messages').html());
  }

});