export default class Mapa {
  constructor(elementId) {
    this.map = new google.maps.Map(document.getElementById(elementId), {
      center: { lat: 41.850033, lng: -87.6500523 },
      zoom: 3
    })
    this.markers = []
  }

  addMarker(lat, lng, title) {
    if (lat && lng) {
      const marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        title
      })

      this.markers.push(marker)
    }
  }
}

function isVisible(elment) {
  var vpH = $(window).height(), // Viewport Height
    st = $(window).scrollTop(), // Scroll Top
    y = $(elment).offset().top

  return y <= vpH + st
}

$(document).ready(function() {
  console.log($('#map-program').length)
  if ($('#map-program').length > 0) {
    var map = new Mapa('map-program')
    const lat = $('#map-program').data('lat')
    const lng = $('#map-program').data('lng')
    const title = $('#map-program').data('title')
    if (lat && lng) {
      map.addMarker(lat, lng, title)
    }
  }

  if ($('.fixed-bottom').length > 0) {
    $(window).scroll(function() {
      if (isVisible($('.footer'))) {
        $('.fixed-bottom').css('position', 'relative')
      } else {
        $('.fixed-bottom').css('position', 'fixed')
      }
    })
  }
})
