document.addEventListener("DOMContentLoaded", (_e) => {
  console.info("custom events manager - enabled");
  let custom_event_form = document.querySelector("form#track_events_form");

  if (custom_event_form) {
    let eventable_elements = document.querySelectorAll("[eventable_data]")

    eventable_elements.forEach((element) => {
      element.addEventListener("click", (_e) => {
        sent_custom_event_form(custom_event_form, element.getAttribute('eventable_data'))
      })
    })
  }

})

function sent_custom_event_form(form, data) {
  if (data !== null) {
    try {
      data = JSON.parse(data)

      form.elements.entity_name.value = data.entity_name;
      form.elements.entity_id.value = data.entity_id;
      form.elements.action_name.value = data.action_name;
      form.requestSubmit()
    } catch (error) {
      console.info("error to track event", error.message)
    }
  }
}
