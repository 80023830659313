import { ModalMessage } from './message'

$(document).ready(function() {
  $(document).on('click', '.feed-read', function() {
    const id = $(this).data('id')
    const url = `/news_items/${id}/link_count`
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      cache: false
    })
      .done(function(data) {
        console.log('track read')
      })
      .fail(function() {
        console.log('error')
      })
  })

  $('.show-testimonial').on('click', function() {
    const text = $(this).data('message')
    const title = $(this).data('title')
    const msg = new ModalMessage()
    msg.showMsg(title, `<p>${text}</p>`)
  })

  $('.show-testimonial-mobile').on('click', function() {
    window.location = $(this).data('url')
  })

  $('input#search-dhome-location-name').on('keydown', _.debounce(function() {
    const val = $(this).val();
    const empty = val.length == 0
    if (empty){
      $(this).val("")
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: '/search/reset_location',
        cache: false
      });
    }
  }, 100));

  $('input#search-location').on('keydown', _.debounce(function() {
    const val = $(this).val();
    const empty = val.length == 0
    if (empty){
      $(this).val("");
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: '/search/reset_location',
        cache: false
      });
    }
  }, 100));

  $('.news-message').readmore({ substr_len: 50 })

  $('.main-gallery-news').flickity({
    wrapAround: false,
    freeScroll: false,
    prevNextButtons: false,
    cellAlign: 'center',
    draggable: '>1',
    initialIndex: 1
  })

  $('.gps_location').on('click', function() {
    location(['#search-location', '#search-dhome-location'])
  })

  function location(elements) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        if ($('#search-lat').length > 0) {
          $('#search-lat').val(position.coords.latitude)
          $('#search-lng').val(position.coords.longitude)
        }
        if ($('#search-dhome-lat').length > 0) {
          $('#search-dhome-lat').val(position.coords.latitude)
          $('#search-dhome-lng').val(position.coords.longitude)
        }

        getReverseGeocodingData(
          position.coords.latitude,
          position.coords.longitude,
          elements
        )
      })
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  function getReverseGeocodingData(lat, lng, elements) {
    const latlng = new google.maps.LatLng(lat, lng)
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ latLng: latlng }, function(results, status) {
      if (status !== google.maps.GeocoderStatus.OK) {
        console.log('location not accesible')
      }
      if (status == google.maps.GeocoderStatus.OK) {
        let address = ''
        for (let i = 0, len = results.length; i < len; i++) {
          let ac = results[i]
          if (ac.types.indexOf('locality') >= 0) {
            $.each(elements, function(index, element) {
              $(element).val(results[i].formatted_address)
            })
            $('#search-location-name').val(results[i].formatted_address)
          }
        }
      }
    })
  }

  if ($('#search-lat').length > 0) {
    const lat = $('#search-lat').val()
    const lng = $('#search-lng').val()
    if (!lat && !lng) {
      location(['#search-location'])
    } else {
      getReverseGeocodingData(lat, lng, ['#search-location'])
    }
  }

  if ($('#search-dhome-lat').length > 0) {
    const lat = $('#search-dhome-lat').val()
    const lng = $('#search-dhome-lng').val()
    if (!lat && !lng) {
      location(['#search-dhome-location'])
    } else {
      getReverseGeocodingData(lat, lng, ['#search-dhome-location'])
    }
  }

  $(document).on('click', '.search-service-item', function() {
    const item = $(this)
    if (item.data('dhome')) {
      // search home desktop
      $('#search-dhome-keyword-id').val(item.data('id'))
      $('#search-dhome-keyword').val(item.html())
      $('form#form-dhome-search').submit()
    } else {
      $('#search-keyword-id').val(item.data('id'))
      $('#search-keyword-text').html(item.html())
      $('form#form-home-search').submit()
    }
  })

  $('#search-keyword-text').on('click', function() {
    const lat = $('input#search-lat').val()
    const lon = $('input#search-lng').val()
    var filter_url = $(this).data('url')
    filter_url = filter_url + `?user_lat=${lat}&user_lon=${lon}`
    window.location = filter_url
  })
  $('.back-icon').on('click', function() {
    window.history.back()
  })

  if ($('#search-location').length > 0) {
    initAutocompleteCity('search-location')
  }

  $('#search-location').on('keydown', function() {
    $('#search-lat').val('')
    $('#search-lng').val('')
  })

  $('#search-dhome-location').on('keydown', function() {
    $('#search-dhome-lat').val('')
    $('#search-dhome-lng').val('')
  })

  if ($('#search-dhome-location').length > 0) {
    initAutocompleteCityHome('search-dhome-location')
  }

  function initAutocompleteCity(elementId) {
    var autocomplete_city = new google.maps.places.Autocomplete(
      document.getElementById(elementId),
      { types: ['(cities)'], componentRestrictions: { country: 'us' } }
    )

    autocomplete_city.setFields(['address_component', 'geometry'])

    //autocomplete_city.addListener('place_changed', searchCity)
    google.maps.event.addListener(
      autocomplete_city,
      'place_changed',
      function() {
        const place_city = autocomplete_city.getPlace()
        const lat = place_city.geometry.location.lat()
        const lng = place_city.geometry.location.lng()
        // alert(`initAutocompleteCity lat: ${lat} lng: ${lng} place_city: ${place_city.geometry.location} viewport: ${place_city.geometry.viewport}`)
        $('#search-lat').val(lat)
        $('#search-lng').val(lng)
        if ($(`#${elementId}`).data('search')) {
          var input = $('<input>')
            .attr('name', 'search_by_location')
            .val(true)
          $(input).attr('type', 'hidden')
          $('form#form-home-search').append($(input))
          $('form#form-home-search').submit()
        }
      }
    )
  }

  function initAutocompleteCityHome(elementId) {
    var autocomplete_cityhome = new google.maps.places.Autocomplete(
      document.getElementById(elementId),
      { types: ['(cities)'], componentRestrictions: { country: 'us' } }
    )

    autocomplete_cityhome.setFields(['address_component', 'geometry'])

    //autocomplete_cityhome.addListener('place_changed', searchCity)
    google.maps.event.addListener(
      autocomplete_cityhome,
      'place_changed',
      function() {
        const place_city = autocomplete_cityhome.getPlace()
        const lat = place_city.geometry.location.lat()
        const lng = place_city.geometry.location.lng()
        $('#search-dhome-lat').val(lat)
        $('#search-dhome-lng').val(lng)
      }
    )
  }
})
