$( document ).ready(function() {
  $("form.report-form").validate({
    rules: {
      'report[reason]': {
        required: true,
        maxlength: 500,
        minlength: 20
      }
    }
  });

});