import { Helper } from './helper'

$( document ).ready(function() {

    $(document).on('change',"#case_history_id", function(){
      const case_id = $(this).val();
      window.location.href ="/history?case_id="+case_id;
    
    });
    
    const case_id = Helper.urlParam('case_id', window.location.href);   
    $("#case_history_id").val(case_id);
    
    $('#case_history_id').selectpicker('refresh'); 

});

