
$( document ).ready(function() {
  
  $("#form_object_disability_id").change(function(){
    const disability = $("#form_object_disability_id option:selected").text(); //id of the service

    if(disability == 'Yes'){
      $('.form-group#percent-disability').show();
    }else{
      $('.form-group#percent-disability').hide();
      $("#form_object_percent_disability option:selected").prop("selected", false);
      $("#form_object_percent_disability").selectpicker("refresh");
    }

  });

  $("#form_object_military_history_disability_id").change(function(){
    const disability = $("#form_object_military_history_disability_id option:selected").text(); //id of the service

    if(disability == 'Yes'){
      $('.form-group#percent-disability').show();
    }else{
      $('.form-group#percent-disability').hide();
      $("#form_object_military_history_percent_disability option:selected").prop("selected", false);
      $("#form_object_military_history_percent_disability").selectpicker("refresh");
    }

  });

  if ( $("#formSponsorInput").val() ){
    loadChapter(); //call initial setting
  }
  $("#formSponsorInput").change(function(){
    loadChapter(false);
  });


  function loadChapter(initial=true){
    const sponsorId = $("#formSponsorInput").val(); //id of the service
    const chapterId = $("#formSponsorInput").data("chapter");
    const chapter = $("#formChapterInput");
    chapter.find('option')
              .not(':first')
              .remove()
              .end();

    if (sponsorId){
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: `/enterprises/${sponsorId}/chapters.json`,
        cache: false,
      })
      .done(function(data){
        data.map( item =>{
        chapter.append($("<option></option>")
                        .attr("value",item.id)
                        .text(item.name));
        });
        if (initial){
          chapter.val(chapterId);
        }
        chapter.prop("disabled", false); 
        chapter.selectpicker('refresh');
        
      })
      .fail(function(){
        console.log("no chapters");
      });
    }else{
      chapter.prop("disabled", true); 
      chapter.selectpicker('refresh');
    }
  
  }
  
  if ( $("#formService").val() ){
    loadServiceSubcategories();
  }
  $("#formService").change(function(){
    loadServiceSubcategories(false);
  });
  function loadServiceSubcategories(initial=true){
    const serviceId = $("#formService").val();
    const subcategoryId = $("#formService").data("subcategory");
    const subService = $("#formSubService");
    subService.find('option')
                .not(':first')
                .remove()
                .end();


    if (serviceId){
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: '/service_needs_categories/'+serviceId,
        cache: false,
      })
      .done(function(data){
          data.map( item =>{
          subService.append($("<option></option>")
                          .attr("value",item.id)
                          .text(item.subcategory));
          });
          if (initial){
            subService.val(subcategoryId);
          }
          subService.prop("disabled", false); 
          subService.selectpicker('refresh');
          
      })
      .fail(function(){
          console.log("no subcategories");
      });
    }

    
  }

  /*Desktop*/
  
  $("#editMyInfo").on("click", function(){
    $("#formMyInfo").toggleClass('d-none');
    $("#dataMyInfo").toggleClass('d-none');
  });

  $("#cancel-my-info").on("click", function(){
    $("#formMyInfo").toggleClass('d-none');
    $("#dataMyInfo").toggleClass('d-none');
  });

  $("#editMilitary").on("click", function(){
    $("#formMilitary").toggleClass('d-none');
    $("#dataMilitary").toggleClass('d-none');
  });
  
  $("#cancel-military-info").on("click", function(){
    $("#formMilitary").toggleClass('d-none');
    $("#dataMilitary").toggleClass('d-none');
  });

  $("#editServiceNeeds").on("click", function(){
    $("#formServiceNeeds").toggleClass('d-none');
    $("#dataService").toggleClass('d-none');
  });
  
  $("#cancel-service-info").on("click", function(){
    $("#formServiceNeeds").toggleClass('d-none');
    $("#dataService").toggleClass('d-none');
  });




  const cityMyInfo = document.getElementById('form_object_city');

  if (cityMyInfo)
  {

    const autocompleteCityMyInfo = new google.maps.places.Autocomplete((cityMyInfo), {types: ['(cities)'], componentRestrictions: {country: "us"}});

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocompleteCityMyInfo.setFields(['address_component']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocompleteCityMyInfo.addListener('place_changed', fillInMyInfo);

    function fillInMyInfo() {
      // Get the place details from the autocomplete object.
      var placeCityMyInfo = autocompleteCityMyInfo.getPlace();

      console.log(placeCityMyInfo);

      for (var i = 0; i < placeCityMyInfo.address_components.length; i++)
      {
        var addrComp = placeCityMyInfo.address_components[i];
        if (addrComp.types[0] == 'administrative_area_level_1')
        {
          var state = addrComp.short_name;
        }

        if (addrComp.types[0] == 'locality')
        {
          var city = addrComp.long_name;
          document.getElementById('form_object_city').value = city;
        }

        if (addrComp.types[0] == 'postal_code')
        {
          var zip = addrComp.long_name;
          document.getElementById('form_object_zip').value = zip;
        }
        else
        {
          document.getElementById('form_object_zip').value = ''; 
        }

      }

      // first, clear old state
      $("#form_object_state_id option").prop('selected', false).trigger("change");

      console.log(state)

      // an update it with teh recently extracted from data
      $("#form_object_state_id option[short_name='"+state+"']").prop('selected', true).trigger("change");
    };

  }

  



  const cityForm = document.getElementById('form_object_my_info_city');

  if (cityForm)
  {

    const autocompleteCityForm = new google.maps.places.Autocomplete((cityForm), {types: ['(cities)'], componentRestrictions: {country: "us"}});

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocompleteCityForm.setFields(['address_component']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocompleteCityForm.addListener('place_changed', fillInMyInfo);

    function fillInMyInfo() {
      // Get the place details from the autocomplete object.
      var placeCityMyInfo = autocompleteCityForm.getPlace();

      console.log(placeCityMyInfo);

      for (var i = 0; i < placeCityMyInfo.address_components.length; i++)
      {
        var addrComp = placeCityMyInfo.address_components[i];
        if (addrComp.types[0] == 'administrative_area_level_1')
        {
          var state = addrComp.short_name;
        }

        if (addrComp.types[0] == 'locality')
        {
          var city = addrComp.long_name;
          document.getElementById('form_object_my_info_city').value = city;
        }

        if (addrComp.types[0] == 'postal_code')
        {
          var zip = addrComp.long_name;
          document.getElementById('form_object_my_info_zip').value = zip;
        }
        else
        {
          document.getElementById('form_object_my_info_zip').value = ''; 
        }

      }

      // first, clear old state
      $("#form_object_my_info_state_id option").prop('selected', false).trigger("change");

      console.log(state)

      // an update it with teh recently extracted from data
      $("#form_object_my_info_state_id option[short_name='"+state+"']").prop('selected', true).trigger("change");
    };

  }

});