$( document ).ready(function() {
  $("#case_id").on('change', function(){
    
    var caseID = $(this).val();
    if (caseID){
      document.location.replace('/case?case_id='+caseID);
    }else{
      document.location.replace('/case');
    }
  });


  $("#case_id_info").on('change', function(){
    var caseID = $(this).val();
    if (caseID){
      $(".case_info_view").show();
      $(".case_info_help").hide();
      document.location.replace('/case/info?case_id='+caseID);
    }else{
      $(".case_info_view").hide();
      $(".case_info_help").show();
    }
  });

  

  $("#case_id_edit").on('change', function(){
    var caseID = $(this).val();
    if (caseID){
      document.location.replace('/case/edit?case_id='+caseID);
    }
  });

});