import { ModalMessage } from './message'

$(function() {
  $('[data-toggle="tooltip"]').tooltip()
})

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  $('.selectpicker').selectpicker('mobile')
}

$(document).ready(function() {
  //when click YES or NO buttons it will hide if another is open
  $(document).on(
    'show.bs.collapse',
    '.contacted-options .collapse',
    function() {
      const $myGroup = $('.contacted-options')
      $myGroup.find('.collapse.show').collapse('hide')
    }
  )

  //share a program on mobile
  $(document).on('click', '.share-program', function() {
    const msg = new ModalMessage()
    let program = $(this).data('program') || $(this).dataset.program
    /*if (navigator.share !== undefined) {
      const title = $(this).data('title') || $(this).dataset.title;
      const url = $(this).data('url') || $(this).dataset.url;
      const text = $(this).data('text') || $(this).dataset.text;
      if (title && url && text){
        navigator.share({
          title,
          text,
          url
        }).then(() => console.log('Successful share'))
          .catch((error) => console.log(error));
      }else{
        if (program){
          $('#myShareModal').modal('show');
          $("#program_share_id").val(program);
        }else{
          msg.showMsg('Not Supported','Unfortunately, this feature is not supported on your browser');
        }
      }
    } else {*/
    if (program) {
      $('#myShareModal').modal('show')
      $('#program_share_id').val(program)
    } else {
      msg.showMsg(
        'Not Supported',
        'Unfortunately, this feature is not supported on your browser.'
      )
    }
    //}
  })

  //rating
  $(document).on('click', '.star', function() {
    event.preventDefault()
    let onStar = parseInt($(this).data('star'), 10)
    const parentElement = $(this).parent('.list-stars')
    parentElement.find('input[name="rating"]').val(onStar)
    for (let i = 1; i <= onStar; i++) {
      parentElement.find(`.star${i}`).text('star')
    }

    const noStar = onStar + 1
    for (let j = noStar; j < 6; j++) {
      parentElement.find(`.star${j}`).text('star_border')
    }
    const url = parentElement.data('url')
    if (url) {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url,
        data: { value: onStar }
      }).done(function(data, textStatus, res) {
        $('.list-stars').removeClass('d-flex')
      })
    }
  })

  $('.favorite-program')
    .not(":contains('favorite_border')")
    .addClass('favorite-heart')

  // favorite
  $(document).on('click', '.favorite-program', function() {
    const id = $(this).data('id')
    const ask = $(this).data('check') //only on favorites page I'll ask before uncheck program
    var element = this
    if (ask && $(element).hasClass('favorite-heart')) {
      const msg = new ModalMessage()
      msg.confirmDialog(
        'Favorite Program',
        'Are you sure you want to remove this program from your favorites?',
        answer => {
          if (answer) {
            favorite(element, id, removeFavorite)
          }
        }
      )
    } else {
      favorite(element, id)
    }
  })

  function favorite(element, id, callback) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/programs/' + id + '/favorite',
      cache: false,
      data: {}
    }).done(function(data) {
      if (data.action == 'created') {
        $(element)
          .addClass('favorite-heart')
          .html('favorite')
        $('.favorite-program[data-id="' + id + '"]')
          .addClass('favorite-heart')
          .html('favorite')
      } else {
        $(element)
          .removeClass('favorite-heart')
          .html('favorite_border')
        $('.favorite-program[data-id="' + id + '"]')
          .removeClass('favorite-heart')
          .html('favorite_border')
      }
      if (callback) {
        callback()
      }
    })
  }

  function removeFavorite() {
    const msg = new ModalMessage()
    msg.showMsg('Favorite Program', 'Program removed from favorites.', () => {
      window.location.reload()
    })
  }

  // contacted feature
  // when a user press YES button on Program Contacted section
  $(document).on('click', '.button-contacted-yes', function() {
    event.preventDefault()
    const id = $(this).data('id')
    const case_id = $(this).data('case-id')

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/programs/' + id + '/contact_yes',
      cache: false,
      data: { case_id: case_id }
    }).done(function(data) {
      if (data.status == 'ok') {
        $('#contact-status-' + id).html(data.my_activity_contact_legend)
      } else if (data.status == 'none') {
        console.log("Status doesn't change")
      } else {
        console.log('Error')
      }
    })
  })

  // handle submit feedback button
  $(document).on('click', '.submit-form-contact', function() {
    const program_id = $(this).data('id')
    const type = $(this).data('type')
    const valuesToSubmit = $('#' + type + '-' + program_id).serialize()
    const msg = new ModalMessage()
    //validate selection
    const checked = $('#' + type + '-' + program_id).find('input:radio:checked')
    const reason = $('#' + type + '-' + program_id).find('textarea')
    if (checked.length != 1) {
      msg.showMsg('Contact', 'Please, Select a Reason!')
      return false
    } else {
      const value = checked[0].value
      if (
        (value == 'Other (not contacted)' || value == 'Other (contacted)') &&
        !reason.val()
      ) {
        msg.showMsg('Contact', 'Please, Fill a reason!')
        return false
      }
    }

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/programs/' + program_id + '/contact',
      cache: false,
      data: valuesToSubmit
    }).done(function(data) {
      if (type == 'negative' || type == 'program_negative') {
        $('#collapseNo' + program_id).collapse('hide')
        $('#collapseContactNo' + program_id).collapse('hide')
      } else {
        $('#collapseYes' + program_id).collapse('hide')
        $('#collapseContactYes' + program_id).collapse('hide')
      }

      msg.showMsg(
        'Feedback Received',
        'Thank you for helping us improve PatriotLink for everyone.'
      )
      $('#contact-status-' + program_id).html(data.my_activity_contact_legend)
    })

    return false
  })

  /*FOR DESKTOP ONLY */

  $(document).on('show.bs.collapse', '.contact-section .collapse', function() {
    const $myGroup = $('.contact-section')
    $myGroup.find('.collapse.show').collapse('hide')
  })

  $(document).on('click', '.cancel-form-contact', function() {
    const $myGroup = $('.contact-section')
    $myGroup.find('.collapse.show').collapse('hide')
  })

  $(document).on('click', '.share_multiple', function() {
    let program = $(this).data('program')
    if (program) {
      $('#myShareModal').modal('show')
      $('#program_share_id').val(program)
    } else {
      $('#program_share_id').val('')
      if ($("input[name='shares[]']:checked").length > 0) {
        $('#myShareModal').modal('show')
      } else {
        const msg = new ModalMessage()
        msg.showMsg(
          'Share Programs',
          'Please select one or more programs from the list.'
        )
      }
    }
  })
})
