import { ModalMessage } from './message'
$( document ).ready(function() {

    $(".disclaimer").on('click', function(){
        const msg = new ModalMessage();
        msg.showMsg('Disclaimer',`<p>Code of Support Foundation (COSF) provides the information presented on or through the <a href="https://www.patriotlink.org" target="_blank">www.patriotlink.org</a> (PATRIOTlink) website for general information purposes only, as a service to the public and its website subscribers.</p>

        <p>While we endeavor to keep the information contained within the PATRIOTlink website up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</p>
        
        <p>We disclaim any responsibility or liability for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from or in connection with, the use of PATRIOTlink website. Any reliance you place on such information or its use is therefore strictly at your own risk.</p>
        
        <p>Through this website you may be able to link directly to other websites and internet resources which are not under COSF’s control. We have no control over the nature, content and availability of those sites. All products or services offered, and all information, content, statements and/or opinions provided or expressed, through those websites or resources, are solely the responsibility of the person or entity providing those websites or resources. We are not responsible for, and disclaim any liability relating to, your use or reliance on said websites or resources.The inclusion of any links to websites and internet resources does not necessarily imply COSF recommends or endorses the products and services offered, or views and opinions expressed within, those sites or resources.</p>
        
        <p>Every effort is made to keep the website up and running smoothly. However, COSF takes no responsibility for, and will not be liable for, the PATRIOTlink website being temporarily unavailable due to technical issues beyond our control.</p>`);
    });

});