export class  ModalMessage {
  showMsg = (header, text, handler) => {
    const $msgModal = $("#myMsgModal").modal({ show : false, keyboard : false});
    $msgModal
      .find('.modal-title').text(header).end()
      .find('.modal-body').html(text).end()
      .modal('show');
    
    $(".btn-close").click(function () {
        if (handler){
          handler(true);
        }
        $("#myMsgModal").modal("hide");
    });
  }
  confirmDialog = (title, message, handler) => {
    $(`<div class="modal fade" id="myConfirmModal" role="dialog"> 
       <div class="modal-dialog"> 
          <div class="modal-content"> 
            <div class="modal-header">
              <h5 class="modal-title">${title}</h5>
            </div>
             <div class="modal-body"> 
               <div class="text-center">${message}</div> 
              </div> 
              <div class="modal-footer">
                 <button class="btn btn-primary btn-yes">Remove</button> 
                 <button class="btn btn-outline-dark btn-no">Cancel</button> 
             </div> 
         </div> 
      </div> 
    </div>`).appendTo('body');
   
    //Trigger the modal
    $("#myConfirmModal").modal({
       backdrop: 'static',
       keyboard: false
    });
    
     //Pass true to a callback function
     $(".btn-yes").click(function () {
         handler(true);
         $("#myConfirmModal").modal("hide");
     });
      
     //Pass false to callback function
     $(".btn-no").click(function () {
         handler(false);
         $("#myConfirmModal").modal("hide");
     });
  
     //Remove the modal once it is closed.
     $("#myConfirmModal").on('hidden.bs.modal', function () {
        $("#myConfirmModal").remove();
     });
  }
};

