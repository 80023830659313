import { ModalMessage } from './message'

$( document ).ready(function() {

  $(document).on('keyup','#share_message', function (e) {
    const text = $(this).val();
    const limit = $(this).data('shared_max_length') || 250;
    if(text.length>limit){
          $(this).val(text.substr(0, limit));
    }
    const length = $(this).val().length;
    const remain = limit - length;
    $('#share_char_counter').html(remain);
  });

  $(document).on("click","#closeShared", function(){
    $('#myShareModal').modal('hide');
  });

  $(document).on('click','#emailShared', function(){

    $("#errorShare").html('');
    const program = $("#program_share_id").val();
    const programs = $("input[name='shares[]']:checked");
      
    if ( programs.length == 0 && program == ''){
      $("#errorShare").html('Please select one or more programs from the list.');
      return false;
    } 

    const message = $('#share_message').val();
    if (!message){
      $("#errorShare").html('Please, write a message!');
      return false;
    }

    const recipient = $('#emailRecipients').val();
    if (!recipient){
      $("#errorShare").html('Please, add an email!');
      return false;
    }

    let program_ids= [];
    if (program != ''){
      program_ids.push(program);
    }

    programs.map(function(){
      program_ids.push($(this).val())
    });
    const data = {
      program_ids: program_ids,
      message,
      recipient
    };

    $.ajax({
      type: 'POST',
      url: "/share",
      data,
      dataType: 'json',
      cache: false
    })
    .done( function (res) {
        $('#myShareModal').modal('hide');
        $('#share_message').val('');
        $('#emailRecipients').val('');
        programs.prop('checked', false);
    }).fail(function(){
      $("#errorShare").html("Coudn't send the email. Please, Try again!");
    });

  });
  
});