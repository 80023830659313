$(document).ready(function() {
  $(document).on('click', '.filter', function() {
    const parameter = $(this).data('parameter')
    const type = $(this).data('type')
    const queryParameters = {}
    const queryString = location.search.substring(1)
    const re = /([^&=]+)=([^&]*)/g
    let m

    // Creates a map with the query string parameters
    while ((m = re.exec(queryString))) {
      queryParameters[decodeURIComponent(m[1])] = decodeURIComponent(m[2])
    }
    queryParameters[parameter] = type

    location.search = $.param(queryParameters)
  })

  $('.keyword-filter').typeahead(
    {
      hint: true,
      highlight: true,
      minLength: 1,
      limit: Infinity
    },
    {
      name: 'keywords',
      display: 'value',
      source: function(query, syncResults, asyncResults) {
        const url = $('.keyword-filter').data('url')
        $.get(`${url}?q=${query}`, function(data) {
          console.log(data)
          asyncResults(data)
        })
      }
    }
  )
})
